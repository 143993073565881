.footer_container {
    text-align: center;
    color: rgba(18, 18, 18, 0.8);
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0 auto;
    opacity: 0.7;
    width: 95vw;
}

.footer_container h5 {
    opacity: 0.6;
    margin: 0;
}

.footer_container h4 {
    opacity: 0.6;
}

.footer_container i {
    color: rgba(33, 33, 33, 0.633);
    transition: all 0.25s ease;
    text-decoration: none !important;
}

.footer_container i:hover {
    transform: translateY(-5px);
    color: rgba(9, 9, 9, 0.849);
    cursor: pointer;
}

.connect-details p {
    margin: 0.05rem;
    font-size: 0.75rem;
}