.home_img img {
    height: 15rem;
}

.home_container {
    text-align: left;
    padding: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

#home {
    /* padding-bottom: 75px; */
    margin-top: 25px;
    width: 70vw;
}

@media (max-width: 600px) {
    #home {
        width: 80vw;
    }
}

.home-header {
    display: flex;
    align-items: flex-end;
}

.home-dots {
    display: flex;
    width: calc(35px + 1.35vw);
    padding: 7px;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 15px;
}

.home-dot {
    background-color: #c1c1c1;
    opacity: 0.5;
    width: calc(4px + 0.24vw);
    height: calc(4px + 0.24vw);
    border-radius: 100%;
    cursor: pointer;
}

.home-dot:hover {
    opacity: 0.7;
}

.selected {
    opacity: 1;
}

.tiny {
    font-size: 0.6rem !important;
}

.muted_p {
    color: #7e7e7e;
    font-size: 0.95rem;
    margin: 0 1rem 1rem;
    line-height: 2;
    transition: all 0.25s ease;
    padding: 0 2rem;
}

.home_down_arrow {
    transform: rotate(180deg) translateY(0);
    width: 1rem;
    opacity: 0.8;
    margin: 0 auto;
    padding: 1rem 1rem;
    padding-bottom: 3rem;
}

.home_down_arrow:hover {
    transform: rotate(180deg) translateY(-5px);
}

.home_button {
    text-decoration: none;
    display: inline-block;
    border: 2px solid rgba(255, 255, 255, 0);
    padding: 0.5rem 1rem;
    margin: 1rem;
    font-size: 0.9rem;
    transition: all 0.25s ease;
    color: rgba(33, 33, 33, 0.74);
    width: 100px;
    border: 2px solid rgba(33, 33, 33, 0.74);
}

.home_button:hover {
    transform: translateY(-5px);
    cursor: pointer;
}

@keyframes clicked {
    0% {
        opacity: 1
    }
    50% {
        opacity: 0.65;
    }
    100% {
        opacity: 1
    }
}

.home_button:active {
    animation: clicked 0.4s ease;
}

@media (max-width: 670px) {
    .home_button {
        display: block;
        margin: 1rem auto;
    }
}

@media (max-width: 768px) {
    .muted_p {
        padding: 0 0rem;
    }
}

@media (max-width: 420px) {
    .home-header {
        display: block;
    }
}

.home-text {
    cursor: pointer;
    color: whitesmoke;
    font-size: calc(20% + 1vw);
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.home-text:hover {
    opacity: 0.6;
}

@media (max-width: 275px) {
    .emoji {
        display: none;
    }
}

.imessage {
    background-color: #1e1e1e;
    border: 1px solid #1e1e1e;
    text-align: left;
    position: absolute;
    top: -20%;
    right: 0;
    transform: translateX(100%);
    transition: all 0.2s ease;
    display: table;
    width: fit-content;
    margin: 0 auto;
    animation: float 3s ease-in-out infinite;
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1;
    }
}

@keyframes float {
    0% {
        top: -20%;
    }
    50% {
        top: -15%;
    }
    100% {
        top: -20%;
    }
}

.imessage:hover {
    cursor: pointer;
}

.imessage p {
    animation: fadeIn 1s linear;
    border-radius: 0.85rem;
    line-height: 1.25;
    padding: 0.5rem .875rem;
    position: relative;
    word-wrap: break-word;
    width: max-content !important;
}

.imessage p::before,
.imessage p::after {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
}

.imessage .sent {
    position: absolute;
    top: 25%;
    right: 0;
    transform: translate(130%, -50%);
    opacity: 0;
    animation: fadeIn 1s linear;
    border-radius: 0;
    line-height: 1;
    font-size: x-small;
    padding: 0;
    animation: none;
}

.imessage .show {
    opacity: 0.5;
}

p.from-me {
    align-self: flex-end;
    background-color: #248bf5;
    color: #1e1e1e;
}

p.from-me::before {
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid #248bf5;
    right: -0.35rem;
    transform: translate(0, -0.1rem);
}

p.from-me::after {
    background-color: #1e1e1e;
    border-bottom-left-radius: 0.5rem;
    right: -40px;
    transform: translate(-30px, -2px);
    width: 10px;
}

p[class^="from-"] {
    margin: 0;
    width: fit-content;
}

p.from-me~p.from-me {
    margin: 0.25rem 0 0;
}

p.from-me~p.from-me:not(:last-child) {
    margin: 0.25rem 0 0;
}

p.from-me~p.from-me:last-child {
    margin-bottom: 0.5rem;
}

p.from-them {
    align-items: flex-start;
    background-color: #333333;
    color: white;
}

p.from-them:before {
    border-bottom-right-radius: 0.8rem 0.7rem;
    border-left: 1rem solid #333333;
    left: -0.35rem;
    transform: translate(0, -0.1rem);
}

p.from-them::after {
    background-color: #1e1e1e;
    border-bottom-right-radius: 0.5rem;
    left: 20px;
    transform: translate(-30px, -2px);
    width: 10px;
}

p[class^="from-"].emoji {
    background: none;
    font-size: 2.5rem;
}

p[class^="from-"].emoji::before {
    content: none;
}

.no-tail::before {
    display: none;
}

.margin-b_none {
    margin-bottom: 0 !important;
}

.margin-b_one {
    margin-bottom: 1rem !important;
}

.margin-t_one {
    margin-top: 1rem !important;
}

@media screen and (max-width: 800px) {
    body {
        margin: 0
    }
    .container {
        padding: 0
    }
    .imessage {
        font-size: 1.05rem;
        margin: 0 auto 1rem;
        /* max-width: 600px; */
        padding: 0;
    }
    .imessage p {
        margin: 0;
    }
}

@media (max-width: 775px) {
    .imessage {
        top: 0;
        left: 50%;
        transform: translate(-50%, -155%);
    }
    @keyframes float {
        0% {
            top: 0%;
        }
        50% {
            top: -5%;
        }
        100% {
            top: 0%;
        }
    }
    p.from-them:before {
        display: none;
    }
    .imessage .sent {
        display: none;
    }
}

.loading {
    height: 14px;
}

.loading .dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #c1c1c1;
    display: block;
    float: left;
    margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.loading .dot:first-child {
    margin-left: 0;
}

.loading .dot.one {
    animation: cycleOne 1s ease-in-out infinite;
    animation-direction: normal;
}

.loading .dot.two {
    animation: cycleTwo 1s ease-in-out infinite;
    animation-direction: normal;
}

.loading .dot.three {
    animation: cycleThree 1s ease-in-out infinite;
    animation-direction: normal;
}

@keyframes cycleOne {
    0% {
        background: rgba(150, 150, 150, 0.4);
    }
    33.333% {
        background: rgba(150, 150, 150, 1);
    }
    66.6667% {
        background: rgba(150, 150, 150, 0.4);
    }
    100% {
        background: rgba(150, 150, 150, 0.4);
    }
}

@keyframes cycleTwo {
    0% {
        background: rgba(150, 150, 150, 0.4);
    }
    33.333% {
        background: rgba(150, 150, 150, 0.4);
    }
    66.6667% {
        background: rgba(150, 150, 150, 1);
    }
    100% {
        background: rgba(150, 150, 150, 0.4);
    }
}

@keyframes cycleThree {
    0% {
        background: rgba(150, 150, 150, 0.4);
    }
    33.333% {
        background: rgba(150, 150, 150, 0.4);
    }
    66.6667% {
        background: rgba(150, 150, 150, 0.4);
    }
    100% {
        background: rgba(150, 150, 150, 1);
    }
}