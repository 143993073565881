.main {
    width: 100%;
    height: 100%;
    background-size: cover;
    overflow-x: hidden;
}

#root {
    background-color: #1e1e1e;
    padding: 0 20px;
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

body {
    margin: 0;
    background-color: #1e1e1e !important;
}

body>* {
    width: auto !important;
}

* {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    transition: all 0.2s ease;
    transition-property: all !important;
}

*:focus {
    outline: none !important;
}

i {
    letter-spacing: 0;
}

::-webkit-scrollbar {
    height: 0px;
    background: transparent;
}

.git {
    width: 1.3rem;
    margin: 0.2rem 0.2rem 0 0.2rem;
    opacity: 0.7;
}

.git:hover {
    opacity: 1;
}

.emoji {
    width: calc(10px + 1vw);
    margin: 0 6px;
}

.small {
    display: flex;
    align-items: center;
    margin-top: 15px;
    text-align: left;
    color: gray;
    font-size: calc(15% + 0.75vw);
    border-radius: 5px;
    cursor: pointer;
    opacity: 0.75;
    border: 1px solid gray;
    padding: 4.5px 8px;
    width: max-content;
}

.small:hover {
    opacity: 1;
}

.small-2 {
    display: flex;
    align-items: center;
    text-align: left;
    color: gray;
    font-size: calc(20% + 0.75vw);
    border-radius: 5px;
    cursor: pointer;
    opacity: 1;
    padding: 4.5px 5px;
    width: max-content;
}

.small-3 {
    display: flex;
    align-items: center;
    text-align: left;
    color: gray;
    font-size: calc(20% + 0.6vw);
    border-radius: 5px;
    cursor: pointer;
    opacity: 0.75;
    padding: 4.5px 5px;
    padding-left: 0;
    width: max-content;
}

* {
    transition: all 0.25s ease;
    scrollbar-width: none;
}

.link {
    color: white;
    background-color: #333333;
    border-radius: 5px;
    padding: 6px 12px;
    font-size: auto;
    text-align: center;
    margin: 0 auto;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 2px solid transparent;
    width: calc(350px + 0.5vw);
    line-height: 1.5;
}

.link:hover {
    border-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
    flex-direction: row;
}

.home-link {
    font-size: auto;
    width: calc(75px + 1.15vw);
    margin: calc(10px - 0.05vw);
}

.dynamic-margin {
    margin: 0 calc(10px - 0.25vw);
}

@media (max-width: 700px) {
    .home-link {
        margin: calc(8px - 0.15vw);
    }
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.section-body {
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
}

.show-left-arrow {
    width: 10px;
    margin-left: 10px;
    opacity: 1;
}

.hide-left-arrow {
    width: 0px;
    margin-left: 0px;
    opacity: 0;
}

.show-right-arrow {
    transform: rotate(180deg);
    width: 10px;
    margin-right: 10px;
    opacity: 1;
}

.hide-right-arrow {
    transform: rotate(180deg);
    width: 0px;
    margin-right: 0px;
    opacity: 0;
}

.popover-selected {
    opacity: 1;
}

.popover-unselected {
    opacity: 0.5;
}

.popover-unselected:hover {
    opacity: 0.9;
}

.proj-link {
    margin: 0 3px;
    width: 18px;
    cursor: pointer;
    opacity: 0.7;
}

.proj-link:hover {
    opacity: 1;
}